import { When } from 'react-if';

import { Badge, Flex, HStack, TruncateMarkup } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { capitalize } from 'shared_DEPRECATED/utils';

import { ChallengeGoalsType } from 'features/challenge/config/types';

type TChallengeInfoGoalsProps = {
  goals: ChallengeGoalsType;
};

export const ChallengeInfoGoals = ({ goals }: TChallengeInfoGoalsProps) => {
  if (!goals || !goals.length) {
    return null;
  }

  return (
    <>
      <Spacer size="sm zr zr" />
      <Flex gap="0.25rem" flexWrap="wrap">
        {goals.map(({ goalId, title, prioritized }) => (
          <Badge key={goalId} whiteSpace="wrap">
            <HStack>
              <When condition={prioritized}>
                <span>
                  <Icon
                    name={iconNames.flagNew}
                    fill="var(--fgInteractive)"
                    stroke="var(--fgInteractive)"
                    width={14}
                  />
                </span>
              </When>
              <TruncateMarkup ellipsis={<span>...</span>}>
                <span title={title}>{capitalize(title)}</span>
              </TruncateMarkup>
            </HStack>
          </Badge>
        ))}
      </Flex>
    </>
  );
};
