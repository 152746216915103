import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { logError } from 'lib/sentry/logError';
import { useSidebarContext } from 'shared';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  createTemporaryJournalNote,
  JournalNote,
  JournalNotePOSTType,
  useJournalQueryActions,
} from 'features/journal';
import { ParticipantSprint } from 'features/sprint';
import { useSprints } from 'features/sprint/hooks';
import { User } from 'features/user/User';

import { showErrorToast } from 'shared/components/Toast';

export const useJournalAddNoteMutation = () => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  const { user, participantEmail } = useSidebarContext() as {
    user: User;
    participantEmail: string;
  };
  const { data: sprints } = useSprints(participantEmail) as {
    data: ParticipantSprint[];
  };

  const [currentSprint] = sprints;

  const {
    cancelJournalNoteQueries,
    updateJournalNoteQueryData,
    updateJournalRelevantQueries,
    getJournalRelevantQueries,
    cancelJournalRelevantQueries,
  } = useJournalQueryActions();

  return useMutation(
    async (data: JournalNotePOSTType) =>
      await request({
        url: `/api/journal/notes/create`,
        body: mapJournalNotePostData({
          ...data,
          sprintId: currentSprint?.sprintId,
        }),
      }),
    {
      onMutate: async ({
        noteType,
        text,
        challengeId,
        challengeFailReasons,
      }: JournalNotePOSTType) => {
        cancelJournalNoteQueries();

        const temporaryJournalNote = createTemporaryJournalNote({
          user,
          text,
          noteType,
          challengeFailReasons,
          challengeId,
          sprint: currentSprint,
        });
        const relevantQueries = getJournalRelevantQueries();

        cancelJournalRelevantQueries();

        const previousJournalNotesData = relevantQueries.map(([key, data]) => ({
          key,
          data,
        }));

        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: [temporaryJournalNote, ...page.notes],
          }));
        });

        return {
          previousJournalNotesData,
          temporaryJournalNoteId: temporaryJournalNote.noteId,
        };
      },
      onSuccess: (newNote: JournalNote, _, context) => {
        updateJournalRelevantQueries((oldData) => {
          oldData.pages = oldData.pages.map((page: any) => ({
            ...page,
            notes: page.notes.map((note: JournalNote) =>
              context?.temporaryJournalNoteId === note.noteId ? newNote : note
            ),
          }));
        });

        updateJournalNoteQueryData(newNote.noteId, () => newNote);
      },
      onError: (err, _, context) => {
        showErrorToast(
          "There's an error with creating note. Please try again later."
        );

        logError(err);

        context?.previousJournalNotesData.forEach(({ key, data }: any) => {
          queryClient.setQueryData(key, data);
        });
      },
    }
  );
};

const mapJournalNotePostData = ({
  noteType,
  text,
  sprintId,
  challengeId,
  challengeFailReasons,
  mediaIds,
  postToFeed,
}: JournalNotePOSTType) => ({
  noteType,
  text,
  sprintId,
  mediaIds,
  postToFeed,
  challengeFailReasons,
  challengeId,
  challengeStatusDate: challengeId
    ? dateUtils(new Date()).format('YYYY-MM-DD')
    : null,
});
