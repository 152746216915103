import { VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';

import { useJournalAnalytics } from 'app/analytics/useJournalAnalytics';
import { Flex, SidebarBody, Typography, useSidebarContext, Box } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  JournalCardReplies,
  JournalNotesContainer,
  useJournalContext,
  mapNotesToSprints,
  JournalNote,
  useJournalEditNoteMutation,
  JournalForm,
  useJournalMetadataQuery,
  JournalNoteCardReactions,
  JournalFormHeader,
  JournalFormHeaderTitle,
  JournalCardUnreadIndicator,
  JournalAddNoteButton,
  JournalCard,
  groupNotesByDate,
} from 'features/journal';
import { User } from 'features/user/User';
import { useUserMode } from 'features/user/hooks';

import { JournalNotesBodyHeader } from './Body/Header';

export const JournalNotesBody = () => {
  const [editedNote, setEditedNote] = useState<Nullable<JournalNote>>(null);
  const { notes, scrollRef, isFetchingNextPage } = useJournalContext();
  const { mutateAsync: editNote } = useJournalEditNoteMutation();
  const { user, participantId } = useSidebarContext() as {
    user: User;
    participantId: string;
  };
  const { sendNoteEditedAnalyticsEvent } = useJournalAnalytics();

  const userMode = useUserMode();

  const { data: { sprints } = { sprints: [] } } = useJournalMetadataQuery({
    participantId,
  });

  const groupedNotes = useMemo(() => {
    if (!notes) {
      return [];
    }

    if (sprints?.length) {
      return mapNotesToSprints(notes, sprints);
    }

    return groupNotesByDate(notes);
  }, [notes, sprints]);

  const handleEditNote = async ({ text }: { text: string }) => {
    const noteId = editedNote?.noteId ?? '';
    setEditedNote(null);

    await editNote({ text, noteId });

    sendNoteEditedAnalyticsEvent();
  };

  return (
    <SidebarBody dataTestid="notes-sidebar-body">
      {userMode === USER_TYPES.USER && !editedNote && <JournalAddNoteButton />}
      <JournalNotesContainer>
        <When condition={Boolean(notes)}>
          {() => (
            <>
              <For of={groupedNotes}>
                {({
                  sprintId,
                  sprintTitle,
                  formattedDate,
                  notesByDate,
                }: {
                  sprintId?: string;
                  sprintTitle?: string;
                  formattedDate?: string;
                  notesByDate: { [dateKey: string]: JournalNote[] };
                }) => {
                  const isSprintPresent = Boolean(sprintId);

                  return (
                    <Box as="section">
                      {isSprintPresent && (
                        <JournalNotesBodyHeader
                          title={sprintTitle!}
                          date={formattedDate!}
                        />
                      )}
                      <For in={notesByDate}>
                        {(notes, { key: notesDate }) => (
                          <Flex flexDirection="column" width="100%">
                            <Spacer size="sm">
                              <Typography
                                textAlign="center"
                                color="gray"
                                type="small"
                              >
                                {dateUtils(notesDate as string).format('MMM D')}
                              </Typography>
                            </Spacer>
                            <VStack p="0 0.5rem 0 1rem">
                              <For of={notes}>
                                {(note: JournalNote) => {
                                  const isAuthor = Boolean(
                                    note.authorId === user.userId
                                  );

                                  return (
                                    <Flex
                                      flexDirection="column"
                                      width="100%"
                                      gap="1rem"
                                      position="relative"
                                    >
                                      {!note.isRead && (
                                        <JournalCardUnreadIndicator
                                          options={{
                                            left: '-0.75rem',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                          }}
                                        />
                                      )}
                                      <JournalCard
                                        note={note}
                                        setEditedNote={setEditedNote}
                                        isAuthor={isAuthor}
                                      >
                                        <JournalCardReplies
                                          repliesCount={note.repliesCount}
                                          noteId={note.noteId}
                                          hasUnreadReplies={
                                            note.hasUnreadReplies
                                          }
                                          isAuthor={isAuthor}
                                          isNoteRead={note.isRead}
                                        />
                                        <JournalNoteCardReactions
                                          noteId={note.noteId}
                                          reactions={note.reactions}
                                          isNoteRead={note.isRead}
                                        />
                                      </JournalCard>
                                    </Flex>
                                  );
                                }}
                              </For>
                            </VStack>
                          </Flex>
                        )}
                      </For>
                    </Box>
                  );
                }}
              </For>
              <div ref={scrollRef}></div>
              <Spacer size="md">
                {isFetchingNextPage ? <Loader size="sm" /> : null}
              </Spacer>
            </>
          )}
        </When>
      </JournalNotesContainer>
      {editedNote && (
        <Box
          position="sticky"
          bottom="0"
          bg="var(--bgPrimary)"
          p="1rem"
          boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
        >
          <JournalForm
            text={editedNote.text}
            onSave={handleEditNote}
            placeholderText="Edit note text..."
          >
            <JournalFormHeader onCancel={() => setEditedNote(null)}>
              <JournalFormHeaderTitle>Edit note</JournalFormHeaderTitle>
            </JournalFormHeader>
          </JournalForm>
        </Box>
      )}
    </SidebarBody>
  );
};
