import { ColorModeScript } from '@chakra-ui/react';
import { ClickToComponent } from 'click-to-react-component';
import { createRoot } from 'react-dom/client';

import App from 'app/App';
import 'lib/amplitude/init';
import 'lib/hotjar/init';
import 'shared_DEPRECATED/config/date';

import { theme } from 'shared/theme';

import { TurnOffDefaultPropsWarning } from './lib/recharts/suppressWarning';

const root = createRoot(document.getElementById('root'));
root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ClickToComponent />
    <TurnOffDefaultPropsWarning />
    <App />
  </>
);
